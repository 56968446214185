<template>
	<div>
        <v-container fluid class="grid-list-xl pb-0 mt-12">
            <!-- Historical Portfolio Results -->
            <v-row class="mx-2">
                <v-col cols="12" sm="12" md="12" class="lightred--text pl-1 text-center mb-5 mt-3">
                    <span class="fw-bold font-2x">{{$t("home.historicalPortfolioResults")}}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="pl-0">
                    <portfolio-history-line-chart></portfolio-history-line-chart>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="pl-0">
                    <nav-line-chart-quasar></nav-line-chart-quasar>
                </v-col>
            </v-row>
        </v-container>
        <app-footer></app-footer>
        <mobile-btn></mobile-btn>
	</div>
</template>
<style>

</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    import PortfolioHistoryLineChart from "@/highcharts/PortfolioHistoryLineChart";
    import NavLineChartQuasar from "@/highcharts/NAVLineChartQuasar";
    export default {
        data(){
            return {
                
            }
        },
        components: {
            PortfolioHistoryLineChart, NavLineChartQuasar
        },
        created(){

        },
        mounted(){

        },
        computed: {
            
        },
        watch:{

        },
        methods: {

        },
    }
</script>